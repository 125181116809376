import { clsx } from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoginModalContainer from '@/components/auth/LoginModalContainer';
import SignupModalContainer from '@/components/auth/SignupModalContainer';
import { EPage, PAGE_PATHNAME } from '@/constants/pages';
import { ESearchFilters } from '@/constants/searchFilters';
import {
  RV_CAMPSITE_BUNDLES_LANDING_PAGE,
  RV_CAMPSITE_BUNDLES_RESORT_PAGE,
} from '@/constants/urls';
import { useAppLayout } from '@/context/AppLayoutContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useIsSearchPage } from '@/hooks/useIsSearchPage';
import useQueryParams from '@/hooks/useQueryParams';
import { useRouter } from '@/hooks/useRouter';
import { useGetWishlists } from '@/hooks/useWishlists';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { setWishlistedRentals } from '@/redux/modules/wishlists';
import { ERentalType } from '@/services/analytics/types';
import { useSerpGuidedSearchDecision } from '@/services/experiments/serpGuidedSearch';
import { useSerpLocalitySearchDecision } from '@/services/experiments/serpLocalitySearchResults';

import { Impersonated } from '../auth/Impersonated/Impersonated';
import GlobalHeader from '../ui/GlobalHeader/GlobalHeader';
import { FixedFooter } from './footer/FixedFooter';
import Footer from './footer/Footer';
import { HelpMenuContainer } from './help-menu';
import { SitewideBanner } from './SitewideBanner';
import SystemDowntimeNotice from './SystemDowntimeNotice';

const HIDDEN_HEADER_AND_FOOTER_ROUTES = [
  '/rv-rental/[location]/[slug]/photos',
  '/rv-rental/[location]/[slug]/reviews',
  '/stay-rental/[location]/[slug]/photos',
  '/stay-rental/[location]/[slug]/reviews',
  '/campsite-rental/[location]/[slug]/photos',
  '/campsite-rental/[location]/[slug]/reviews',
  '/pro/[slug]/photos',
  '/pro/[slug]/reviews',
  '/pro/[slug]/reviews/[page]',
  '/trips/[slug]/cancel',
  '/campground-rental/[location]/[slug]/photos',
  '/campground-rental/[location]/[slug]/reviews',
  '/destinations/outdoorsy-hill-country',
  '/destinations/outdoorsy-hill-country/terms-and-conditions',
  '/guided-search',
];

const HIDDEN_HEADER_AND_FOOTER_START_WITH_ROUTES = ['/checkout'];

const MOBILE_HIDDEN_HEADER_AND_FOOTER_ROUTES = [
  '/trips/[slug]',
  '/rv-rental/[location]/[slug]',
  '/wishlists',
  '/wishlists/[id]',
  '/wishlists/recently-viewed',
];

const HIDDEN_FIXED_FOOTER_START_WITH_ROUTES = [
  '/rv-search',
  '/owner',
  '/checkout',
  '/rv-rental',
  '/stay-rental',
  '/campsite-rental',
  '/trips/',
  '/security',
  '/campground-rental',
  '/guide/seasonal',
];

const FULL_PAGE_CANVAS_ROUTES = [
  '/',
  RV_CAMPSITE_BUNDLES_LANDING_PAGE,
  '/stationary-delivery',
  '/invite/b/[code]',
  '/become-a-host',
  '/generations-in-the-wild',
  RV_CAMPSITE_BUNDLES_RESORT_PAGE,
];

const HIDDEN_NAV_SEARCH = ['/locality', '/locality-directory-by-type'];

const FLUID_PAGE_ROUTES = [
  PAGE_PATHNAME[EPage.SEARCH],
  '/guide/seasonal/summer',
  '/guide/seasonal/fall',
  '/guide/seasonal/winter',
  '/wishlists/[id]',
];

const TRANSPARENT_HEADER_ROUTES = [RV_CAMPSITE_BUNDLES_LANDING_PAGE];
const TRANSPARENT_HEADER_ROUTES_ONLY_DESKTOP = ['/gift-cards'];
const TRANSPARENT_HEADER_START_WITH_ROUTES = [
  '/guide/seasonal/summer',
  '/guide/seasonal/fall',
  '/guide/seasonal/winter',
];

const NON_STICKY_HEADER_START_WITH_ROUTES = [
  '/guide/seasonal/summer',
  '/guide/seasonal/fall',
  '/gift-cards',
  '/guide/seasonal/winter',
];

const DARK_NAVIGATION = ['/gift-cards'];

const WHITE_HEADER_ROUTES = ['/about/outdoorsy-group'];
const WHITE_HEADER_MOBILE_ROUTES = ['/gift-cards'];

const ApplicationLayout = ({ children, pageProps }) => {
  const router = useRouter();
  const { isMobile } = useBreakpoint();
  const { isSearchPage } = useIsSearchPage();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);

  const isNewLayout =
    pageProps.isRvBundlesPage || pageProps.isHomePage || pageProps.isRvDeliveryPage;

  const serpGuidedSearchEnabled =
    useSerpGuidedSearchDecision().enabled && router.pathname.startsWith('/locality');

  const isLocalitySerpExperient =
    useSerpLocalitySearchDecision().enabled && router.pathname.startsWith('/locality');

  const queryParams = useQueryParams();
  const [showSearchBar, setShowSearchBar] = useState(false);

  const isSignatureCampgroundBookASite =
    router.pathname.startsWith('/campground-rental') && queryParams.signature === 'true';

  const shouldHideHeaderAndFooter =
    isSignatureCampgroundBookASite ||
    HIDDEN_HEADER_AND_FOOTER_ROUTES.includes(pageProps.pathname || router.pathname) ||
    HIDDEN_HEADER_AND_FOOTER_START_WITH_ROUTES.some(route => router.pathname.startsWith(route)) ||
    (isMobile &&
      MOBILE_HIDDEN_HEADER_AND_FOOTER_ROUTES.includes(pageProps.pathname || router.pathname));

  const mobileShouldHideHeaderAndFooter = MOBILE_HIDDEN_HEADER_AND_FOOTER_ROUTES.includes(
    pageProps.pathname || router.pathname,
  );

  const shouldHideFixedFooter = HIDDEN_FIXED_FOOTER_START_WITH_ROUTES.some(route =>
    router.pathname.startsWith(route),
  );

  const showFixedFooter = !shouldHideHeaderAndFooter && !shouldHideFixedFooter;

  const isFluidPage = FLUID_PAGE_ROUTES.includes(router.pathname);

  const isFullCanvasPage = FULL_PAGE_CANVAS_ROUTES.includes(pageProps.pathname || router.pathname);
  const headerIsTransparent =
    TRANSPARENT_HEADER_ROUTES.includes(pageProps.pathname || router.pathname) ||
    TRANSPARENT_HEADER_START_WITH_ROUTES.some(route => router.pathname.startsWith(route));

  const headerDesktopTransparent =
    !isMobile && TRANSPARENT_HEADER_ROUTES_ONLY_DESKTOP.includes(router.pathname);

  const hideSearchBarWithExperiment = showFixedFooter || !router.pathname.startsWith('/rv-search');
  const hideSearchBar = HIDDEN_NAV_SEARCH.includes(pageProps.pathname || router.pathname);
  const hideSearchNavbar = isNewLayout || hideSearchBar || hideSearchBarWithExperiment;
  const noSearchNavbar = serpGuidedSearchEnabled ? !showSearchBar : hideSearchNavbar;

  const { data: wishlists } = useGetWishlists({
    isAuthenticated,
    examplesOnly: false,
    excludeExamples: true,
  });

  useEffect(() => {
    // We use SWR to fetch wishlists, but in order to keep using the funtionality build around favorites,
    // we need to keep the redux store updated with the wishlisted rentals
    if (wishlists && isAuthenticated) {
      const wishlistedRentals = wishlists.flatMap(wishlist =>
        wishlist.rentals.map(rId => ({
          added_at: '',
          id: wishlist.id,
          rental_id: rId,
          wishlist_creator_id: wishlist.user_details.find(user => user.user_role === 'owner')?.id,
          wishlist_name: wishlist.title,
          wishlist_id: wishlist.id,
        })),
      );
      dispatch(setWishlistedRentals(wishlistedRentals));
    } else {
      dispatch(setWishlistedRentals([]));
    }
  }, [wishlists, isAuthenticated, dispatch]);

  useEffect(() => {
    if (!serpGuidedSearchEnabled) return;

    const handleScroll = () => {
      const heroSection = document.getElementById('serp-hero-section');
      setShowSearchBar((heroSection.getBoundingClientRect().bottom || 0) < 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [serpGuidedSearchEnabled]);

  const isDarkNavigation =
    !isMobile && DARK_NAVIGATION.some(route => router.pathname.startsWith(route));

  const isHeaderWhite =
    WHITE_HEADER_ROUTES.includes(pageProps.pathname || router.pathname) ||
    WHITE_HEADER_ROUTES.some(route => router.pathname.startsWith(route)) ||
    (isMobile && WHITE_HEADER_MOBILE_ROUTES.includes(router.pathname));

  const isRv =
    !router.query[ESearchFilters.RENTAL_CATEGORY] ||
    router.query[ESearchFilters.RENTAL_CATEGORY] === ERentalType.RV;

  const alwaysStickyHeaders =
    isMobile && (isSearchPage || serpGuidedSearchEnabled || isLocalitySerpExperient) && isRv;
  const nonStickyHeader = NON_STICKY_HEADER_START_WITH_ROUTES.some(route =>
    router.pathname.startsWith(route),
  );

  const hasStickyHeader = !nonStickyHeader && !shouldHideHeaderAndFooter;
  const hasStuckHeaderShadow = isNewLayout && hasStickyHeader;

  const stuckHeaderCheckRef = useRef(null);
  const [headerIsStuck, setHeaderIsStuck] = useState(false);

  const { setHeaderHeight } = useAppLayout();
  const headerRef = useRef(null);

  useEffect(() => {
    if (!hasStuckHeaderShadow || !stuckHeaderCheckRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => setHeaderIsStuck(!!entry && entry.intersectionRatio < 1),
      { rootMargin: '1px 0px 0px 0px', threshold: [1] },
    );

    observer.observe(stuckHeaderCheckRef.current);

    return () => observer.disconnect();
  }, [hasStuckHeaderShadow]);

  useEffect(() => {
    if (!headerRef.current) return;
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setHeaderHeight(entry.contentRect.height);
      }
    });

    observer.observe(headerRef.current);

    return () => observer.disconnect();
  }, [setHeaderHeight]);
  return (
    <>
      <SignupModalContainer />
      <LoginModalContainer />
      <HelpMenuContainer />

      <div className="font-sans" id="app">
        {hasStuckHeaderShadow && <div ref={stuckHeaderCheckRef} />}

        <div
          id="global-headers"
          ref={headerRef}
          className={clsx(
            'relative',
            !nonStickyHeader &&
              !shouldHideHeaderAndFooter && {
                'sticky top-0 z-1299': alwaysStickyHeaders,
                'md:sticky md:top-0 md:z-1299': !alwaysStickyHeaders,
                'shadow-500': headerIsStuck && alwaysStickyHeaders,
                'md:shadow-500': headerIsStuck && !alwaysStickyHeaders,
              },
          )}>
          <Impersonated />
          <SystemDowntimeNotice />
          <SitewideBanner />

          <div
            className={clsx({
              'hidden md:block': mobileShouldHideHeaderAndFooter,
            })}>
            {!shouldHideHeaderAndFooter && (
              <GlobalHeader
                isHomePage={pageProps.isHomePage}
                isRvBundlesPage={pageProps.isRvBundlesPage}
                isRvDeliveryPage={pageProps.isRvDeliveryPage}
                noSearchNavbar={noSearchNavbar && !isLocalitySerpExperient}
                isFluidPage={isFluidPage || isLocalitySerpExperient}
                isFullCanvasPage={isFullCanvasPage}
                isTransparent={headerIsTransparent || headerDesktopTransparent}
                isSticky={isMobile ? alwaysStickyHeaders : !nonStickyHeader}
                isHeaderWhite={isHeaderWhite}
                isDarkNavigation={isDarkNavigation}
              />
            )}
          </div>
        </div>

        <div
          style={isNewLayout ? { backgroundColor: 'var(--page-content-bg-color)' } : {}}
          className={clsx('relative min-h-[60vh]', {
            'transition-[background-color] duration-700 ease-in-out': isNewLayout,
            'bg-canvas-100': isFullCanvasPage,
          })}>
          {children}
        </div>

        <div
          className={clsx({
            'hidden md:block': mobileShouldHideHeaderAndFooter,
          })}>
          {!shouldHideHeaderAndFooter && <Footer isNewLayout={isNewLayout} />}
        </div>
        {showFixedFooter && <FixedFooter isHomePage={isNewLayout} />}
      </div>
    </>
  );
};

export default ApplicationLayout;
