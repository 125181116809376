import React from 'react';
import { useIntl } from 'react-intl';

import { ALERT_TRIANGLE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Tooltip from '@/components/switchback/Tooltip/Tooltip';
interface ILocationSearchInputProps {
  getInputProps: () => Record<string, never>;
  alertStatus?: boolean;
}

export interface IOption {
  label: string;
  groupName: string;
  value?: any;
  url?: string;
}

export interface IGroupOption {
  options: IOption[];
  key: number;
  index: number;
  group: string;
}

const LocationSearchInput: React.FC<ILocationSearchInputProps> = ({
  getInputProps,
  alertStatus,
}) => {
  const intl = useIntl();
  const locationPlaceholder = intl.formatMessage({
    defaultMessage: 'Location',
    id: 'cGCkb9',
    description: 'Global Header > Placeholder text for header search field',
  });

  return (
    <div className="rounded-tl-lg rounded-bl-lg">
      <div className="relative inline-flex flex-row items-center h-12 w-72">
        <div className="z-40 flex items-center w-full h-full py-2 pl-6 pr-4 text-gray-900">
          <input
            className="w-full text-base font-medium text-left text-gray-900 bg-transparent semiHighlight placeholder:text-gray-800"
            data-testid="add-location-input"
            placeholder={locationPlaceholder}
            {...getInputProps()}
          />
        </div>
        {alertStatus && (
          <Tooltip
            content={intl.formatMessage({
              defaultMessage: 'Enter a delivery address for the best results.',
              id: 'b9wuaC',
            })}
            label={intl.formatMessage({ defaultMessage: 'Delivery address info', id: '3NMwKT' })}
            className="absolute right-0 z-50 hidden w-4 h-4 text-gray-900 md:block">
            <Icon name={ALERT_TRIANGLE} size={IconSize.small} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default LocationSearchInput;
