import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { OptimizelyFlags } from './flags';
import { useOptimizelyDecision } from './index';

const serpLocalitySearchResultsDecision = makeVar<{ variation?: string; enabled: boolean }>({
  variation: undefined,
  enabled: false,
});

export const useSerpLocalitySearchDecision = () => {
  return useReactiveVar(serpLocalitySearchResultsDecision);
};

// We would like to initialize the decision only for counties, and be able to use the decision also in the header.
// Using the decision directly causes event viewed to be triggered on any page, since we have the decision in the header.
// To avoid this, render this component only if the locality is a county, and the decision will be initialized,
// and via reactive variables we can use the decision in all the other places.
export const InitSerpLocalitySearchResultsDecision = () => {
  const decision = useOptimizelyDecision(OptimizelyFlags.SEARCH_ON_LOCALITY_PAGES);

  useEffect(() => {
    serpLocalitySearchResultsDecision({
      variation: decision?.variationKey || undefined,
      enabled: decision?.variationKey === 'enabled',
    });
  }, [decision]);

  return null;
};
