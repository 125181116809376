import dayjs from 'dayjs';
import uniq from 'lodash/uniq';
import querystring from 'query-string';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import UniversalSearch from '@/components/ui/SearchHeader/UniversalSearch/UniversalSearch';
import { TAmenitySlug } from '@/constants/amenitiesFilter';
import { EHouseRules } from '@/constants/houseRules';
import { useSearchLocationPopularDestinations } from '@/constants/search';
import { ESearchFilters } from '@/constants/searchFilters';
import { vehicleStyleToTypeMap } from '@/constants/vehicleDetails';
import { usePopularParks } from '@/graphql/hooks/search/getPopularParks';
import { useIsSearchPage } from '@/hooks/useIsSearchPage';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import useQueryParams from '@/hooks/useQueryParams';
import { MAX_RECENT_SEARCHES, useRecentSearches } from '@/hooks/useRecentSearches';
import {
  MAX_RECENT_SEARCHES as MAX_RECENT_SEARCHES_WITH_FILTERS,
  useRecentSearchesWithFilters,
} from '@/hooks/useRecentSearchesWithFilters';
import { useRecentSearchOption } from '@/hooks/useRecentSearchOption';
import { useRouter } from '@/hooks/useRouter';
import {
  IHeaderSearchFilterDates,
  IHeaderSearchFilterGuests,
  setHeaderSearchFilter,
} from '@/redux/modules/globalHeader';
import { applySearchFilter } from '@/redux/modules/search';
import { TRootState } from '@/redux/rootReducer';
import { lastSearchedInSearchBar } from '@/redux/selectors/analytics-selectors/search/searchViewedEventData';
import { getHeaderSearchFilters } from '@/redux/selectors/globalHeader';
import { getQueryParams, parseFlexibleDateRange } from '@/redux/selectors/queryParams';
import {
  getSearchFormDeliveryFilter,
  getSearchFormInstantBookFilter,
} from '@/redux/selectors/search/searchForm';
import { getIsLoading } from '@/redux/selectors/search/searchResults';
import { searchCategorySelectedEvent, trackRenterSearchedEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';
import { parseEventDate } from '@/services/analytics/utils';
import { useExperimentIsEnabled } from '@/services/experiments';
import { OptimizelyFlags } from '@/services/experiments/flags';
import { TQueryParams } from '@/services/searchApiRequest';
import { trackEvent } from '@/services/track-event';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { ILodgingCampgrounds } from '@/services/types/search/lodgingCampgrounds';
import { ERentalCategory } from '@/services/types/search/rentals/id';
import { IVehicle } from '@/services/types/search/vehicle';
import { IVehicleCampgrounds } from '@/services/types/search/vehicleCampgrounds';
import { itemizeAddress } from '@/utility/itemizeAddress';
import { getParamAsString, getParams } from '@/utility/queryParams';
import { getSurfacedLocation, LocationGroupType } from '@/utility/surfacedLocation';

import NavbarSearch from './NavbarSearch/NavBarSearch';
import {
  EHeaderTab,
  HEADER_TOP_MENU_ALL,
  IHeaderMenu,
  TOP_MENU_RVS,
  useSearchModuleHeaderTabs,
  useSearchModuleTabs,
} from './UniversalSearch/UniversalSearchContainer/menu';

export type { IHeaderMenu };

export { EHeaderTab, TOP_MENU_RVS };

export const QUERYSTRING_DATE_FORMAT = 'YYYY-MM-DD';

export interface INavbarSearch {
  location?: { lat: number; lng: number };
  address?: string;
  dates?: IHeaderSearchFilterDates;
  guests?: IHeaderSearchFilterGuests;
  recentSearches?: IAutocompleteOption[];
  maxRecentSearches: number;
  onChangeAddress?: (address?: string) => void;
  onConfirmAddress?: (address?: string) => void;
  onChangeDates: (dates?: IHeaderSearchFilterDates) => void;
  onConfirmDates?: (dates?: IHeaderSearchFilterDates) => void;
  onChangeGuests?: (guests?: IHeaderSearchFilterGuests) => void;
  onConfirmGuests?: (guests?: IHeaderSearchFilterGuests) => void;
  onChangeVehicle?: (vehicle?: IVehicle) => void;
  onChangeVehicleCampgrounds?: (vehicle?: IVehicleCampgrounds) => void;
  onChangeLodgingCampgrounds?: (lodging?: ILodgingCampgrounds) => void;
  onCloseMobile?: () => void;
  onOpenMobile?: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  handleRecentSearchNavigation?: (url: string) => void;
}

interface ISearchHeaderProps {
  className?: string;
  onCloseMobile?: () => void;
  onOpenMobile?: () => void;
  onSubmit?: () => void;
  isHomePage?: boolean;
  isUniversalSearch?: boolean;
  // fully featured universal search with tabs and more filters
  isFullUniversalSearch?: boolean;
  isStay?: boolean;
  // promo search props
  isPromoSearch?: boolean;
  promoParams?: TQueryParams;
  isCampgroundSearch?: boolean;
  isRvBundlesSearch?: boolean;
  isSERP?: boolean;
  isSerpPage?: boolean;
  // preselect a category by default and don't render top menu so we can't change it
  lockedCategory?: IHeaderMenu;
  localitySearchFilters?: Record<string, string>;
  scrollToSearchFilter?: () => void;
}

const SearchHeader: React.FC<ISearchHeaderProps> = ({
  onCloseMobile,
  onOpenMobile,
  isHomePage,
  onSubmit,
  className,
  isUniversalSearch,
  isFullUniversalSearch,
  isStay,
  isPromoSearch,
  promoParams,
  isCampgroundSearch,
  isRvBundlesSearch,
  isSerpPage,
  isSERP,
  lockedCategory,
  localitySearchFilters,
  scrollToSearchFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const addressOptionRef = useRef<any>(null);
  const router = useRouter();
  const dispatch = useDispatch();
  const { isSearchPage } = useIsSearchPage();
  const [recentSearches, { addRecentSearch }] = useRecentSearches();

  const isRecentSearchWithFiltersEnabled = !isPromoSearch;

  const { searchOption } = useRecentSearchOption();
  const topMenus = useSearchModuleTabs();
  const headerTopMenus = useSearchModuleHeaderTabs();

  const isLoading = useSelector(getIsLoading);
  const [openedTab, setOpenedTab] = useState<IHeaderMenu | undefined>(HEADER_TOP_MENU_ALL);
  const [selectedCategory, setSelectedCategory] = useState<IHeaderMenu | undefined>(
    lockedCategory || TOP_MENU_RVS,
  );

  const queryParams = useQueryParams();
  const rentalType = queryParams?.[ESearchFilters.RENTAL_CATEGORY] || ERentalCategory.RV;
  const searchRentalType = isSearchPage ? rentalType : selectedCategory?.tab || ERentalCategory.RV;
  const [recentSearchesWithFilters, { addRecentSearch: addRecentSearchWithFilters }] =
    useRecentSearchesWithFilters(searchRentalType as ERentalCategory);

  const isNotRentalRV = isSearchPage
    ? !!queryParams?.[ESearchFilters.RENTAL_CATEGORY]
    : selectedCategory?.tab !== EHeaderTab.RVS;

  const isGuestOccupancyAvailable = isNotRentalRV || isCampgroundSearch || isRvBundlesSearch;

  // Every time the category (RV/stay/campground) changes, we want to reset the tab back to "all".
  useEffect(() => {
    setOpenedTab(HEADER_TOP_MENU_ALL);
  }, [selectedCategory]);

  const [, { update: updatePrevBaseUrl }] = useLocalStorage('prevBaseUrl');

  const searchMeta = useSelector((state: TRootState) => state.search.meta);
  const { address, dates, guests, vehicle, vehicleCampgrounds, lodgingCampgrounds } =
    useSelector(getHeaderSearchFilters);

  const {
    [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromStore,
    [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromStore,
    [ESearchFilters.DELIVERY]: deliveryFromStore,
  } = useSelector(getSearchFormDeliveryFilter);
  const {
    [ESearchFilters.FILTER_FEATURE]: featuresFromQueryParams,
    [ESearchFilters.DELIVERY]: deliveryFromQueryParams,
  } = useSelector(getQueryParams);
  const isInstantBook = useSelector(getSearchFormInstantBookFilter);

  const location = searchMeta
    ? { lat: searchMeta.lat, lng: searchMeta.lng, city: searchMeta.city }
    : undefined;

  // shows delivery alert in search bar when delivery address is specified and delivery option selected
  const deliveryRevertDecision = useExperimentIsEnabled(
    OptimizelyFlags.REVERT_SETUP_DELIVERY_CHANGE,
  );
  const alertStatus =
    deliveryFromQueryParams === 'true' && !location?.city && !deliveryRevertDecision;

  const searchLocationPopularDestinations = useSearchLocationPopularDestinations();
  const parksResponse = usePopularParks(searchLocationPopularDestinations);

  const popularDestinationsByLocation = parksResponse?.map(park => ({ label: park?.label }));

  const popularDestinations = popularDestinationsByLocation.length
    ? popularDestinationsByLocation
    : searchLocationPopularDestinations;

  const renterSearchedSource = (() => {
    if (isHomePage) return 'home_search';
    if (isSERP) return 'serp_search';
    return 'search_nav';
  })();

  const handleChangeAddress = (value?: string) => {
    if (addressOptionRef?.current) {
      addressOptionRef.current = null;
    }

    dispatch(
      setHeaderSearchFilter({
        address: value,
      }),
    );
  };

  const handleChangeVehicle = useCallback(
    (vehicle?: IVehicle) => {
      if (vehicle) {
        dispatch(
          setHeaderSearchFilter({
            vehicle: vehicle,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleChangeVehicleCampgrounds = useCallback(
    (vehicle?: IVehicleCampgrounds) => {
      if (vehicle) {
        dispatch(
          setHeaderSearchFilter({
            vehicleCampgrounds: vehicle,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleChangeLodgingCampgrounds = useCallback(
    (lodging?: ILodgingCampgrounds) => {
      if (lodging) {
        dispatch(
          setHeaderSearchFilter({
            lodgingCampgrounds: lodging,
          }),
        );
      }
    },
    [dispatch],
  );

  const parseDates = useCallback<
    (dates: IHeaderSearchFilterDates | undefined) => {
      hasDates: string | undefined;
      formattedDateFrom: string | undefined;
      formattedDateTo: string | undefined;
      flexibleDays: string | undefined;
    }
  >(dates => {
    const formattedDateFrom = dates?.from
      ? dayjs(dates.from).format(QUERYSTRING_DATE_FORMAT)
      : undefined;
    const formattedDateTo = dates?.to ? dayjs(dates.to).format(QUERYSTRING_DATE_FORMAT) : undefined;
    const hasDates = formattedDateFrom && formattedDateTo;
    return {
      hasDates,
      formattedDateFrom,
      formattedDateTo,
      flexibleDays: dates?.flexible_days ? String(dates?.flexible_days) : undefined,
    };
  }, []);

  const handleChangeDates = useCallback(
    (value?: IHeaderSearchFilterDates) => {
      dispatch(
        setHeaderSearchFilter({
          dates: value,
        }),
      );

      const before = parseDates(dates);
      const after = parseDates(value);
      trackEvent({
        event: 'search/filter/dates',
        action: 'update',
        before: {
          'date[from]': before.formattedDateFrom,
          'date[to]': before.formattedDateTo,
        },
        after: {
          'date[from]': after.formattedDateFrom,
          'date[to]': after.formattedDateTo,
        },
      });
    },
    [dates, dispatch, parseDates],
  );

  const handleChangeGuests = useCallback(
    (value?: IHeaderSearchFilterGuests) => {
      if (!value || !Object.keys(value).length) {
        dispatch(
          setHeaderSearchFilter({
            guests: undefined,
          }),
        );
        return;
      }

      dispatch(
        setHeaderSearchFilter({
          guests: value,
        }),
      );

      const changedValues = Object.entries(value).filter(
        ([key, value]) => value !== guests?.[key as keyof IHeaderSearchFilterGuests],
      );

      changedValues.forEach(([key, value]) => {
        const event = ['adults', 'children', 'infants'].includes(key)
          ? `search/filter/guests/sleeps[${key}]`
          : `search/filter/guests/${key}`;
        trackEvent({
          event: event,
          action: 'update',
          before: guests?.[key as keyof IHeaderSearchFilterGuests],
          after: value,
        });
      });
    },
    [guests, dispatch],
  );

  const parseGuests = useCallback(
    (guests: IHeaderSearchFilterGuests | undefined) => {
      const { adults, children, infants, seatbelts, sleeps, ...features } = guests || {};
      const formattedFeatures = features
        ? (Object.keys(features) as Array<keyof typeof features>).filter(key =>
            Boolean(features[key]),
          )
        : [];
      const headerFeatures = ['pet_friendly', 'accessible'];

      const filterFeature = featuresFromQueryParams
        ? (featuresFromQueryParams.toString().split(',') as TAmenitySlug[])
        : undefined;
      const searchPageFilters = isSearchPage && filterFeature ? filterFeature : [];
      const searchPageFiltersWithoutHeader = searchPageFilters.filter(
        item => !headerFeatures.includes(item),
      );

      const consolidatedFeaturesList = uniq([
        ...searchPageFiltersWithoutHeader,
        ...formattedFeatures,
      ]);

      return { adults, children, infants, consolidatedFeaturesList, seatbelts, sleeps };
    },
    [featuresFromQueryParams, isSearchPage],
  );

  const removeLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (searchOption.value && isRecentSearchWithFiltersEnabled && !isLoading) {
      addRecentSearchWithFilters(searchOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOption.url, isRecentSearchWithFiltersEnabled, isLoading]);

  const handleSubmit = useCallback<
    (
      overridenValues?: Record<string, string | undefined>,
      options?: { addToRecentSearches?: boolean },
    ) => void
  >(
    async (overridenValues, { addToRecentSearches = true } = {}) => {
      const { formattedDateFrom, formattedDateTo, hasDates, flexibleDays } = parseDates(dates);
      const { adults, children, infants, consolidatedFeaturesList, seatbelts, sleeps } =
        parseGuests(guests);
      const additionalFeatures: string[] = [];
      const isPetFriendlyTab = isFullUniversalSearch && openedTab?.tab === EHeaderTab.PET_FRIENDLY;
      const isFestivalFriendlyTab =
        isFullUniversalSearch && openedTab?.tab === EHeaderTab.FESTIVAL_FRIENDLY;
      if (isPetFriendlyTab) additionalFeatures.push(EHouseRules.PET_FRIENDLY);
      if (isFestivalFriendlyTab) additionalFeatures.push(EHouseRules.FESTIVAL_FRIENDLY);
      const consolidatedFeatures = uniq([...consolidatedFeaturesList, ...additionalFeatures]).join(
        ',',
      );
      const isCampgroundsTab =
        isFullUniversalSearch && selectedCategory?.tab === EHeaderTab.CAMPGROUNDS;
      const isStaysTab = isFullUniversalSearch && selectedCategory?.tab === EHeaderTab.STAYS;
      const isTowableTab = isFullUniversalSearch && openedTab?.tab === EHeaderTab.TOWABLE;
      const isLodgingCampgroundsTab =
        isFullUniversalSearch && openedTab?.tab === EHeaderTab.LODGING_SITE;
      const isRVSiteCampgroundsTab = isFullUniversalSearch && openedTab?.tab === EHeaderTab.RV_SITE;
      const isTentSiteCampgroundsTab =
        isFullUniversalSearch && openedTab?.tab === EHeaderTab.TENT_SITE;

      if (
        address &&
        addToRecentSearches &&
        address !== addressOptionRef?.current?.label &&
        !isRecentSearchWithFiltersEnabled
      ) {
        addRecentSearch(address);
      }

      setLoading(true);

      const clearBounds = address
        ? {
            [ESearchFilters.BOUNDS_NE]: undefined,
            [ESearchFilters.BOUNDS_SW]: undefined,
          }
        : {};
      lastSearchedInSearchBar(1);
      onSubmit?.();

      const hasSelectedCampsiteTab =
        isLodgingCampgroundsTab || isRVSiteCampgroundsTab || isTentSiteCampgroundsTab;

      const campsiteFeaturesQueryParam = isLodgingCampgroundsTab
        ? ESearchFilters.CAMPSITE_LODGING_FEATURES
        : isRVSiteCampgroundsTab
          ? ESearchFilters.CAMPSITE_RV_SITE_FEATURES
          : ESearchFilters.CAMPSITE_TENT_SITE_FEATURES;

      const campgroundBaseQuery = isCampgroundsTab
        ? {
            [ESearchFilters.RENTAL_CATEGORY]: ERentalType.CAMPGROUND,
            [ESearchFilters.CAMPSITE_CATEGORY_TYPES]: hasSelectedCampsiteTab
              ? openedTab.tab
              : undefined,
            [ESearchFilters.CAMPGROUND_FEATURES]: consolidatedFeatures,
            // Intend this to get overriden by tent/lodging/rv specific campsite features if they exist.
            ...(hasSelectedCampsiteTab
              ? { [campsiteFeaturesQueryParam]: consolidatedFeatures }
              : {}),
          }
        : {};

      const campsiteSubTypeQuery =
        isLodgingCampgroundsTab || isRVSiteCampgroundsTab
          ? {
              [ESearchFilters.CAMPSITE_SUPPORTED_SUBTYPES]: isLodgingCampgroundsTab
                ? lodgingCampgrounds?.lodging_group?.join(',')
                : vehicleCampgrounds?.type,
            }
          : {};

      const rvSiteQuery = isRVSiteCampgroundsTab
        ? {
            [ESearchFilters.CAMPSITE_RV_SITE_FEATURES]: uniq([
              ...(vehicleCampgrounds?.hookups || []),
              ...consolidatedFeaturesList,
            ]).join(','),
            [ESearchFilters.CAMPSITE_MAX_VEHICLE_LENGTH]:
              vehicleCampgrounds?.vehicle_length?.toString(),
          }
        : {};

      const isDrivableTab = isFullUniversalSearch && openedTab?.tab === EHeaderTab.DRIVABLE;
      const drivableQuery = isDrivableTab
        ? {
            [ESearchFilters.FILTER_TYPE]: vehicleStyleToTypeMap.drivable.join(','),
          }
        : {};

      const towableQuery = isTowableTab
        ? {
            [ESearchFilters.WEIGHT_LESSER]: vehicle?.max_towing_capacity?.toString() || undefined,
            [ESearchFilters.FILTER_TYPE]: vehicleStyleToTypeMap.towable.join(','),
            [ESearchFilters.SUGGESTED_TOWING_CAPACITY]:
              vehicle?.suggested_towing_capacity?.toString() || undefined,
            [ESearchFilters.TOWING_ID]: vehicle?.id.toString() || undefined,
          }
        : {};

      const isDeliveryTab = isFullUniversalSearch && openedTab?.tab === EHeaderTab.DELIVERABLES;

      const deliveryQuery = isDeliveryTab
        ? {
            [ESearchFilters.DELIVERY]: 'true',
            [ESearchFilters.DELIVERY_ADDRESS]: address,
            [ESearchFilters.DELIVERY_STATIONARY]: EDeliveryOption.STATIONARY,
          }
        : {};

      const stayBaseQuery = isStaysTab
        ? {
            [ESearchFilters.RENTAL_CATEGORY]: ERentalType.STAY,
          }
        : {};

      updatePrevBaseUrl(router.route);

      let newSearchFormFilters: Record<string, string | undefined> = {};
      if (addressOptionRef?.current) {
        const value = addressOptionRef.current.label;
        const isFiltered = !!deliveryStationaryFromStore || !!deliveryFromStore;
        const itemizedAddress = itemizeAddress(addressOptionRef.current?.value);
        const { state, country, city, street, zip } = itemizedAddress;

        newSearchFormFilters = {
          [ESearchFilters.DELIVERY]: String(isFiltered),
          [ESearchFilters.DELIVERY_ADDRESS]: value,
          [ESearchFilters.DELIVERY_CENTER]: JSON.stringify(
            addressOptionRef.current.value?.center || [],
          ),
          [ESearchFilters.DELIVERY_DETAILS]: encodeURIComponent(
            JSON.stringify({
              country,
              state,
              city,
              street,
              zip,
            }),
          ),
          [ESearchFilters.DELIVERY_QUERY]: value,
          [ESearchFilters.DELIVERY_LOCATION_ID]: addressOptionRef.current.value?.id || '',
        };
        if (deliveryFromQueryParams === 'true') {
          trackEvent({
            event: 'search/filter/delivery',
            action: 'update',
            before: deliveryAddressFromStore,
            after: value,
          });
        }
      } else {
        // keep search address and delivery in sync
        // when manually typing in the search bar
        const isFiltered = !!deliveryStationaryFromStore || !!deliveryFromStore;
        newSearchFormFilters = {
          [ESearchFilters.DELIVERY]: String(isFiltered),
          [ESearchFilters.DELIVERY_ADDRESS]: address,
          [ESearchFilters.DELIVERY_QUERY]: address,
        };
      }

      const {
        [ESearchFilters.DELIVERY]: deliveryFromStore2,
        [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromStore2,
        [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromStore2,
        [ESearchFilters.DELIVERY_CENTER]: deliveryCenterFromStore,
        [ESearchFilters.DELIVERY_DETAILS]: deliveryDetailsFromStore,
        [ESearchFilters.DELIVERY_QUERY]: deliveryQueryFromStore,
        [ESearchFilters.DELIVERY_LOCATION_ID]: deliveryLocationIdFromStore,
      } = newSearchFormFilters;

      const deliveryAddressParams =
        deliveryAddressFromStore ||
        deliveryStationaryFromStore ||
        deliveryCenterFromStore ||
        deliveryLocationIdFromStore ||
        deliveryQueryFromStore
          ? {
              ...(deliveryFromStore2 && { [ESearchFilters.DELIVERY]: String(deliveryFromStore2) }),
              ...(deliveryAddressFromStore2 && {
                [ESearchFilters.DELIVERY_ADDRESS]: String(deliveryAddressFromStore2),
              }),
              ...(deliveryCenterFromStore && {
                [ESearchFilters.DELIVERY_CENTER]: deliveryCenterFromStore,
              }),
              ...(deliveryCenterFromStore && {
                [ESearchFilters.DELIVERY_DETAILS]: deliveryDetailsFromStore,
              }),
              ...(deliveryLocationIdFromStore && {
                [ESearchFilters.DELIVERY_LOCATION_ID]: String(deliveryLocationIdFromStore),
              }),
              ...(deliveryQueryFromStore && {
                [ESearchFilters.DELIVERY_QUERY]: String(deliveryQueryFromStore),
              }),
              ...(deliveryStationaryFromStore2 && {
                [ESearchFilters.DELIVERY_STATIONARY]: String(deliveryStationaryFromStore2),
              }),
            }
          : {};

      const parsedAddress = overridenValues?.address ? overridenValues.address : address;

      const flexibleDateRange = parseFlexibleDateRange(
        formattedDateFrom,
        formattedDateTo,
        flexibleDays,
      );
      await trackRenterSearchedEvent({
        surfacedLocations: getSurfacedLocation(),
        totalAdults: adults || 0,
        totalChildren: children || 0,
        totalInfants: isGuestOccupancyAvailable ? infants || 0 : null,
        locationQuery: parsedAddress || null,
        source: renterSearchedSource,
        isADAAccessible: consolidatedFeatures.includes('accessible'),
        isPetFriendly: consolidatedFeatures.includes('pet_friendly'),
        flexibleDateRange: flexibleDateRange,
        departureDate: (formattedDateFrom && parseEventDate(formattedDateFrom)) || null,
        returnDate: (formattedDateTo && parseEventDate(formattedDateTo)) || null,
        hasCategories: !!isFullUniversalSearch,
        selectedCategory: openedTab?.tab || EHeaderTab.ALL,
        towableYear: isTowableTab && vehicle?.year ? vehicle.year.toString() : null,
        towableMake: isTowableTab && vehicle?.make ? vehicle.make : null,
        towableModel: isTowableTab && vehicle?.model ? vehicle.model : null,
        rentalType: isStay ? ERentalType.STAY : ERentalType.RV,
        searchFilterCategory: null,
        lastQuestionSeen: null,
      });

      const instantBookQuery = isInstantBook
        ? { [ESearchFilters.INSTANT_BOOK]: String(isInstantBook) }
        : {};

      router.events.on('routeChangeComplete', removeLoading);

      if (isCampgroundSearch || isRvBundlesSearch) {
        dispatch(
          applySearchFilter(
            {
              [ESearchFilters.DATE_FROM]: hasDates ? formattedDateFrom : undefined,
              [ESearchFilters.DATE_TO]: hasDates ? formattedDateTo : undefined,
              [ESearchFilters.FLEXIBLE_DAYS]: hasDates ? flexibleDays : undefined,
              [ESearchFilters.GUESTS_ADULTS]: adults ? String(adults) : undefined,
              [ESearchFilters.GUESTS_CHILDREN]: children ? String(children) : undefined,
              ...(isGuestOccupancyAvailable && {
                [ESearchFilters.GUESTS_INFANTS]: infants ? String(infants) : undefined,
              }),
              [ESearchFilters.FILTER_FEATURE]: consolidatedFeatures
                ? consolidatedFeatures
                : undefined,
            },
            true,
            true,
            false,
          ),
        );
      } else {
        dispatch(
          applySearchFilter(
            {
              [ESearchFilters.PAGE_OFFSET]: String(0),
              [ESearchFilters.ADDRESS]: address,
              [ESearchFilters.DATE_FROM]: hasDates ? formattedDateFrom : undefined,
              [ESearchFilters.DATE_TO]: hasDates ? formattedDateTo : undefined,
              [ESearchFilters.FLEXIBLE_DAYS]: hasDates ? flexibleDays : undefined,
              [ESearchFilters.GUESTS_ADULTS]: adults ? String(adults) : undefined,
              [ESearchFilters.GUESTS_CHILDREN]: children ? String(children) : undefined,
              [ESearchFilters.SEATBELTS]: seatbelts ? String(seatbelts) : undefined,
              [ESearchFilters.SLEEPS]: sleeps ? String(sleeps) : undefined,
              ...(isGuestOccupancyAvailable && {
                [ESearchFilters.GUESTS_INFANTS]: infants ? String(infants) : undefined,
              }),
              [ESearchFilters.FILTER_FEATURE]: consolidatedFeatures
                ? consolidatedFeatures
                : undefined,
              ...campgroundBaseQuery, // order matters, this one comes before the other campsite params
              ...campsiteSubTypeQuery,
              ...rvSiteQuery,
              ...drivableQuery,
              ...towableQuery,
              ...deliveryQuery,
              ...clearBounds,
              ...deliveryAddressParams,
              ...instantBookQuery,
              ...stayBaseQuery,
              ...(localitySearchFilters || {}),
              ...overridenValues,
            },
            false,
            false,
            true,
            removeLoading,
          ),
        );
      }

      return () => {
        router.events.off('routeChangeComplete', removeLoading);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      guests,
      address,
      dates,
      router,
      vehicle,
      vehicleCampgrounds,
      lodgingCampgrounds,
      parseDates,
      parseGuests,
      addRecentSearch,
      isFullUniversalSearch,
      recentSearches,
      recentSearchesWithFilters,
      onSubmit,
      isRecentSearchWithFiltersEnabled,
      selectedCategory,
      isCampgroundSearch,
      isRvBundlesSearch,
      isGuestOccupancyAvailable,
      renterSearchedSource,
      openedTab,
    ],
  );

  useEffect(() => {
    return () => {
      removeLoading();
    };
  }, []);

  // Update guests filter in case search page's filter changes some of its values
  useEffect(() => {
    if (!isSearchPage && !isCampgroundSearch && !isRvBundlesSearch) {
      return;
    }

    const searchFilterAccessible = featuresFromQueryParams?.includes('accessible');
    const searchFilterPetFriendly = featuresFromQueryParams?.includes('pet_friendly');
    if (
      guests?.accessible !== searchFilterAccessible ||
      guests?.pet_friendly !== searchFilterPetFriendly
    ) {
      dispatch(
        setHeaderSearchFilter({
          guests: {
            ...guests,
            accessible: searchFilterAccessible,
            pet_friendly: searchFilterPetFriendly,
          },
        }),
      );
    }

    // guests is intentionally left out of the dependencies array so it works in one way only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresFromQueryParams, dispatch]);

  const handleRecentSearchNavigation = useCallback(
    async (recentSearchURL: string) => {
      if (!isRecentSearchWithFiltersEnabled || !recentSearchURL) return;

      const urlParams = recentSearchURL.split('?')[1] || '';
      const params = querystring.parse(urlParams);

      const dateFrom = params[ESearchFilters.DATE_FROM];
      const dateTo = params[ESearchFilters.DATE_TO];
      const datesFromParams: IHeaderSearchFilterDates | undefined =
        dateFrom && dateTo
          ? {
              from: dayjs(dateFrom.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
              to: dayjs(dateTo.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
            }
          : undefined;
      const flexibleDays = params[ESearchFilters.FLEXIBLE_DAYS] || undefined;
      const guestsAdults = params[ESearchFilters.GUESTS_ADULTS]
        ? Number(params[ESearchFilters.GUESTS_ADULTS])
        : undefined;
      const guestsChildren = params[ESearchFilters.GUESTS_CHILDREN]
        ? Number(params[ESearchFilters.GUESTS_CHILDREN])
        : undefined;
      const guestsInfants = params[ESearchFilters.GUESTS_INFANTS]
        ? Number(params[ESearchFilters.GUESTS_INFANTS])
        : undefined;
      const features = params[ESearchFilters.FILTER_FEATURE];
      const guestsFeatures = params[ESearchFilters.FILTER_FEATURE]
        ? {
            accessible: !!features?.includes('accessible'),
            pet_friendly: !!features?.includes('pet_friendly'),
            flexible_days: flexibleDays === undefined ? undefined : Number(flexibleDays),
          }
        : undefined;

      const guestsFromParams = {
        adults: guestsAdults,
        children: guestsChildren,
        infants: guestsInfants,
        ...guestsFeatures,
      };

      const { formattedDateFrom, formattedDateTo } = parseDates(datesFromParams);
      const { adults, children, infants, consolidatedFeaturesList } = parseGuests(guestsFromParams);

      const parsedAddress = getParamAsString(params[ESearchFilters.ADDRESS] || undefined);

      const flexibleDateRange = parseFlexibleDateRange(
        formattedDateFrom,
        formattedDateTo,
        flexibleDays,
      );

      await trackRenterSearchedEvent({
        surfacedLocations: LocationGroupType.RECENT_SEARCHES,
        totalAdults: adults || 0,
        totalChildren: children || 0,
        totalInfants: isGuestOccupancyAvailable ? infants || 0 : null,
        locationQuery: parsedAddress || null,
        source: renterSearchedSource,
        isADAAccessible: consolidatedFeaturesList.includes('accessible'),
        isPetFriendly: consolidatedFeaturesList.includes('pet_friendly'),
        flexibleDateRange: flexibleDateRange,
        departureDate: (formattedDateFrom && parseEventDate(formattedDateFrom)) || null,
        returnDate: (formattedDateTo && parseEventDate(formattedDateTo)) || null,
        hasCategories: !!isFullUniversalSearch,
        selectedCategory: openedTab?.tab || EHeaderTab.ALL,
        towableYear: null,
        towableMake: null,
        towableModel: null,
        rentalType: isStay ? ERentalType.STAY : ERentalType.RV,
        searchFilterCategory: null,
        lastQuestionSeen: null,
      });

      // TODO: use client-side transition once the A/B test is finished
      window.location.href = recentSearchURL;
      return;
    },
    [
      isFullUniversalSearch,
      isRecentSearchWithFiltersEnabled,
      isStay,
      openedTab?.tab,
      parseDates,
      parseGuests,
      isGuestOccupancyAvailable,
      renterSearchedSource,
    ],
  );

  const handleConfirmAddress = useCallback(
    (option?: any, skipSubmit?: boolean) => {
      const value = option.label;
      if (!value || !isSearchPage) return;
      addressOptionRef.current = option;
      if (!option?.value?.id?.startsWith('address') && !isRecentSearchWithFiltersEnabled) {
        addRecentSearch(value);
      }
      if (skipSubmit) return;
      handleSubmit(
        {
          [ESearchFilters.ADDRESS]: value,
        },
        { addToRecentSearches: false },
      );
    },

    [isSearchPage, isRecentSearchWithFiltersEnabled, handleSubmit, addRecentSearch],
  );

  const handleConfirmDates = useCallback(
    (dates?: IHeaderSearchFilterDates) => {
      if (!isSearchPage && !isCampgroundSearch && !isRvBundlesSearch) return;

      const { formattedDateFrom, formattedDateTo, hasDates, flexibleDays } = parseDates(dates);
      handleSubmit({
        [ESearchFilters.DATE_FROM]: hasDates ? formattedDateFrom : undefined,
        [ESearchFilters.DATE_TO]: hasDates ? formattedDateTo : undefined,
        [ESearchFilters.FLEXIBLE_DAYS]: hasDates ? flexibleDays : undefined,
      });
    },
    [isSearchPage, isCampgroundSearch, isRvBundlesSearch, parseDates, handleSubmit],
  );

  const handleConfirmGuests = useCallback(
    (guests: Partial<IHeaderSearchFilterGuests>) => {
      if (!isSearchPage && !isCampgroundSearch && !isRvBundlesSearch) return;
      const { adults, children, infants, consolidatedFeaturesList, seatbelts, sleeps } =
        parseGuests(guests);
      const consolidatedFeatures = consolidatedFeaturesList.join(',');

      handleSubmit({
        [ESearchFilters.GUESTS_ADULTS]: adults ? String(adults) : undefined,
        [ESearchFilters.SEATBELTS]: seatbelts ? String(seatbelts) : '0',
        [ESearchFilters.SLEEPS]: sleeps ? String(sleeps) : '0',
        [ESearchFilters.GUESTS_CHILDREN]: children ? String(children) : undefined,
        ...(isGuestOccupancyAvailable && {
          [ESearchFilters.GUESTS_INFANTS]: infants ? String(infants) : undefined,
        }),
        [ESearchFilters.FILTER_FEATURE]: consolidatedFeatures ? consolidatedFeatures : undefined,
      });
    },
    [
      isSearchPage,
      isCampgroundSearch,
      isRvBundlesSearch,
      parseGuests,
      handleSubmit,
      isGuestOccupancyAvailable,
    ],
  );

  const openHeaderTab = (tab: IHeaderMenu, parentRef: RefObject<HTMLUListElement>) => {
    setOpenedTab(tab);
    parentRef?.current?.scrollTo({
      left: ['deliverables', 'towable'].includes(tab.tab) ? 300 : 0,
      behavior: 'smooth',
    });
  };

  const openCategoryTab = (menu: IHeaderMenu) => {
    setSelectedCategory(menu);
    const tab = menu.tab;

    if (tab === EHeaderTab.STAYS || tab === EHeaderTab.RVS || tab === EHeaderTab.CAMPGROUNDS) {
      const rentalCategory =
        tab === EHeaderTab.STAYS
          ? ERentalType.STAY
          : tab === EHeaderTab.RVS
            ? ERentalType.RV
            : ERentalType.CAMPGROUND;
      searchCategorySelectedEvent({
        rentalCategory,
        source: isHomePage ? 'home_search' : 'search_nav',
      });
    }
  };

  // In the first render, fill out all filters in redux based on querystrings
  useEffect(() => {
    const initialParams = getParams(router);
    const initialAddress: string | undefined = initialParams[ESearchFilters.ADDRESS]
      ? initialParams[ESearchFilters.ADDRESS]?.toString()
      : undefined;
    const initalDeliveryAddress = initialParams[ESearchFilters.DELIVERY_ADDRESS]
      ? initialParams[ESearchFilters.DELIVERY_ADDRESS]?.toString()
      : undefined;

    const initialDateFrom = initialParams[ESearchFilters.DATE_FROM];
    const initialDateTo = initialParams[ESearchFilters.DATE_TO];
    const initialFlexibleDays = initialParams[ESearchFilters.FLEXIBLE_DAYS];
    const initialDates: IHeaderSearchFilterDates | undefined =
      initialDateFrom && initialDateTo
        ? {
            from: dayjs(initialDateFrom.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
            to: dayjs(initialDateTo.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
            flexible_days:
              initialFlexibleDays === undefined ? undefined : Number(initialFlexibleDays),
          }
        : undefined;

    const initialGuestsAdults = initialParams[ESearchFilters.GUESTS_ADULTS]
      ? parseInt(String(initialParams[ESearchFilters.GUESTS_ADULTS]), 10)
      : undefined;
    const initialGuestsChildren = initialParams[ESearchFilters.GUESTS_CHILDREN]
      ? parseInt(String(initialParams[ESearchFilters.GUESTS_CHILDREN]), 10)
      : undefined;
    const initialGuestsInfants = initialParams[ESearchFilters.GUESTS_INFANTS]
      ? parseInt(String(initialParams[ESearchFilters.GUESTS_INFANTS]), 10)
      : undefined;
    const initialGuestsSeatbelts = initialParams[ESearchFilters.SEATBELTS]
      ? parseInt(String(initialParams[ESearchFilters.SEATBELTS]), 10)
      : undefined;
    const initialGuestsSleeps = initialParams[ESearchFilters.SLEEPS]
      ? parseInt(String(initialParams[ESearchFilters.SLEEPS]), 10)
      : initialParams[ESearchFilters.GUESTS_ADULTS] || initialParams[ESearchFilters.GUESTS_CHILDREN]
        ? Math.min(
            parseInt(String(initialParams[ESearchFilters.GUESTS_ADULTS]), 10) +
              parseInt(String(initialParams[ESearchFilters.GUESTS_CHILDREN]), 10),
            10,
          )
        : undefined;
    const initialFeatures = getParamAsString(initialParams[ESearchFilters.FILTER_FEATURE]);
    const initialGuestsFeatures = initialFeatures
      ? {
          accessible: initialFeatures.includes('accessible'),
          pet_friendly: initialFeatures.includes('pet_friendly'),
        }
      : undefined;

    const addressFromSearchMeta =
      !searchMeta?.city || !searchMeta?.state
        ? searchMeta?.country_name
        : `${searchMeta?.city}, ${searchMeta?.state}`;

    // we want promo dates to be visible for the user when no regular query dates exist
    // but user-entered values should override the promo ones
    let promoDates: IHeaderSearchFilterDates | undefined;
    if (isPromoSearch && promoParams) {
      const promoDateFrom = promoParams[ESearchFilters.DATE_FROM];
      const promoDateTo = promoParams[ESearchFilters.DATE_TO];
      promoDates =
        promoDateFrom && promoDateTo
          ? {
              from: dayjs(promoDateFrom.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
              to: dayjs(promoDateTo.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
              flexible_days:
                initialFlexibleDays === undefined ? undefined : Number(initialFlexibleDays),
            }
          : undefined;
    }

    const initialState = {
      address: initalDeliveryAddress || addressFromSearchMeta || initialAddress || address,
      dates: initialDates || promoDates,
      guests: {
        adults: initialGuestsAdults,
        children: initialGuestsChildren,
        infants: initialGuestsInfants,
        seatbelts: initialGuestsSeatbelts,
        sleeps: initialGuestsSleeps,
        ...initialGuestsFeatures,
      },
    };

    dispatch(setHeaderSearchFilter(initialState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, dispatch]);

  const recentLocationSearches = recentSearches.map(search => ({
    label: search,
    value: search,
  }));

  if (isCampgroundSearch || isRvBundlesSearch) {
    return (
      <NavbarSearch
        dates={dates}
        guests={guests}
        maxRecentSearches={0}
        onSubmit={handleSubmit}
        loading={loading}
        onChangeDates={handleChangeDates}
        onChangeGuests={handleChangeGuests}
        onConfirmDates={handleConfirmDates}
        onConfirmGuests={handleConfirmGuests}
        onOpen={onOpenMobile}
        onClose={onCloseMobile}
        className={className}
        isCampgroundSearch={isCampgroundSearch}
        isRvBundlesSearch={isRvBundlesSearch}
        isGuestOccupancyAvailable={isGuestOccupancyAvailable}
        scrollToSearchFilter={scrollToSearchFilter}
      />
    );
  }

  return isUniversalSearch ? (
    <UniversalSearch
      location={location}
      address={address}
      dates={dates}
      guests={guests}
      vehicle={vehicle}
      vehicleCampgrounds={vehicleCampgrounds}
      lodgingCampgrounds={lodgingCampgrounds}
      recentSearches={
        isRecentSearchWithFiltersEnabled ? recentSearchesWithFilters : recentLocationSearches
      }
      maxRecentSearches={
        isRecentSearchWithFiltersEnabled ? MAX_RECENT_SEARCHES_WITH_FILTERS : MAX_RECENT_SEARCHES
      }
      onSubmit={handleSubmit}
      loading={loading}
      onChangeAddress={handleChangeAddress}
      onChangeVehicle={handleChangeVehicle}
      onChangeVehicleCampgrounds={handleChangeVehicleCampgrounds}
      onChangeLodgingCampgrounds={handleChangeLodgingCampgrounds}
      onChangeDates={handleChangeDates}
      onChangeGuests={handleChangeGuests}
      onConfirmAddress={handleConfirmAddress}
      onConfirmDates={handleConfirmDates}
      onConfirmGuests={handleConfirmGuests}
      className={className}
      popularDestinations={popularDestinations}
      tabList={topMenus}
      hideTabListMenu={!!lockedCategory}
      headerTabList={headerTopMenus}
      openedTab={openedTab}
      openHeaderTab={openHeaderTab}
      openCategoryTab={openCategoryTab}
      showCategory={isFullUniversalSearch}
      selectedCategory={selectedCategory}
      showStayFilter={isFullUniversalSearch}
      handleRecentSearchNavigation={handleRecentSearchNavigation}
      isGuestOccupancyAvailable={isGuestOccupancyAvailable}
      lockedCategory={lockedCategory}
    />
  ) : (
    <NavbarSearch
      location={location}
      address={address}
      dates={dates}
      guests={guests}
      recentSearches={
        isRecentSearchWithFiltersEnabled ? recentSearchesWithFilters : recentLocationSearches
      }
      maxRecentSearches={
        isRecentSearchWithFiltersEnabled ? MAX_RECENT_SEARCHES_WITH_FILTERS : MAX_RECENT_SEARCHES
      }
      onSubmit={handleSubmit}
      loading={loading}
      onChangeAddress={handleChangeAddress}
      onChangeDates={handleChangeDates}
      onChangeGuests={handleChangeGuests}
      onConfirmAddress={handleConfirmAddress}
      onConfirmDates={handleConfirmDates}
      onConfirmGuests={handleConfirmGuests}
      onOpen={onOpenMobile}
      onClose={onCloseMobile}
      className={className}
      popularDestinations={popularDestinations}
      alertStatus={alertStatus}
      handleRecentSearchNavigation={handleRecentSearchNavigation}
      isPromoSearch={isPromoSearch}
      isGuestOccupancyAvailable={isGuestOccupancyAvailable}
      isSerpPage={isSerpPage}
    />
  );
};

export default SearchHeader;
